import PhysiologyYoyoRepository from '@/shared/http/repositories/socialProject/physiology-yoyo'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading } from '@/shared/utils/UIHelper'
import VueHighcharts from 'vue2-highcharts'

export default {
  components: {
      VueHighcharts
  },

  props: {
    physiologyId: {
      type: String,
      default: {}
    }
  },

  data: () => ({
    options: {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Rendimento da Turma'
      },
      subtitle: {
        text: 'Distância(m)'
      },
      xAxis: {
        type: 'category',
        labels: {
            rotation: -45,
            style: {
                color: '#0d75a3',
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
      },
      yAxis: {
        min: 0,
        title: {
            text: 'Distância Total(m)'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormat: 'Distância dê: <b>{point.y:.1f}(m)</b>'
      },
      series: [{
        name: 'Distâncias',
        dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif',
            }
        }
      }]
    }
  }),

  watch: {
    physiologyId: {
      handler: function (value) {
        if (value) {
          this.loadChargeData(value)
        }
      },
      immediate: true
    }
  },

  methods: {
    loadChargeData(id) {
      loading.push()
      PhysiologyYoyoRepository.GetPhysiologyYoyoByPhysiologyId(id)
      .then(response => {

        if(!IsSuccessCode){
          return Promise.reject()
        }

        const student = response.data.data

        let studentName = []
        let studentDistanceAverage = []
        let orderDescByYoyo = []
        let classAverage = 0
        
        if (student.length != 0) {
          orderDescByYoyo = student.sort(function(a,b) {return b.distance - a.distance;})
          studentName = orderDescByYoyo.filter(sn => sn.distance != null).map(sn => sn.studentName)
          studentDistanceAverage = orderDescByYoyo.filter(sn => sn.distance != null).map( sn => sn.distance)
          classAverage = studentDistanceAverage.reduce((value1, value2) => value1 + value2, 0) / studentDistanceAverage.length
          this.$emit('classAverageDistance', Number(parseFloat(classAverage).toFixed(1)))
          
          studentName.push('MÉDIA DA TURMA')
          studentDistanceAverage.push(classAverage)

          studentName.push('LITERATURA')
          studentDistanceAverage.push(student[0].yoyoLiterature)
        }

        setTimeout(() => {
          this.$refs.lineCharts.chart.update({
            xAxis: {
              categories: studentName,
              text: null
            },
            series: [{
              name: 'Média',
              data: studentDistanceAverage
            }],
            title: {
              text: 'Rendimento da Turma',
              align: 'center'
            },
            subtitle: {
              text: 'Distância Total em Metros',
              align: 'center'
            }
          })

          loading.pop()
        }, 1300)
      })
      .catch(e => {
        loading.pop()
        console.log(`Erro ao tentar obter os dados para o gráfico de rendimentos da turma: \n ${e}`)
      })
    }
  }
}