import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'physiologysprint'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(criteria, pagination) 
})

const GetById = (id, pagination) => SocialProjectManager.get(`${resourceName}/${id}`, { 
  params: merge(pagination) 
})

const GetPhysiologySprintByPhysiologyId = (physiologyId, pagination) => SocialProjectManager.get(`${resourceName}/physiology/${physiologyId}`, { 
  params: merge(pagination) 
})

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const ExportExcel = (id, classAverage) => SocialProjectManager.get(`${resourceName}/download-excel-sprint/${id}`, {
  params: { classAverage }
})

const PhysiologySprintRepository = {
  GetById,
  GetAll, 
  GetPhysiologySprintByPhysiologyId,
  Update,
  Delete,
  ExportExcel
}

export default PhysiologySprintRepository