import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'physiologyreport'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(criteria, pagination) 
})

const GetByStudentName = (criteria) => SocialProjectManager.get(`${resourceName}/student-name`, { 
  params: merge(criteria) 
})

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const GetAllAvgStudentByStudentId = id => SocialProjectManager.get(`${resourceName}/studentId/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const PhysiologyReportRepository = {
  GetAllAvgStudentByStudentId,
  GetByStudentName,
  GetById,
  GetAll,
  Create,
  Update,
  Delete
}

export default PhysiologyReportRepository