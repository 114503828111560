import PhysiologyGraphicAvgStudentSprint from '@/components/layout/PhysiologyGraphicAvgStudentSprint/physiologyGraphicAvgStudentSprint.vue'
import PhysiologyGraphicAvgStudentJump from '@/components/layout/PhysiologyGraphicAvgStudentJump/physiologyGraphicAvgStudentJump.vue'
import PhysiologyGraphicAvgStudentYoyo from '@/components/layout/PhysiologyGraphicAvgStudentYoyo/physiologyGraphicAvgStudentYoyo.vue'
import PhysiologyReportRepository from '@/shared/http/repositories/socialProject/physiology-report'
import ReportPhysiologyCriteria from '@/shared/models/criteria/studentphysiologycriteria'
import GraphicSprint from '@/views/physiology/components/graphicSprint/graphicSprint.vue'
import GraphicYoyo from '@/views/physiology/components/graphicYoyo/graphicYoyo.vue'
import GraphicJump from '@/views/physiology/components/graphicJump/graphicJump.vue'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    PhysiologyGraphicAvgStudentSprint,
    PhysiologyGraphicAvgStudentJump,
    PhysiologyGraphicAvgStudentYoyo,
    GraphicJump,
    GraphicSprint,
    GraphicYoyo
  },

  data: () => ({
    criteria: new ReportPhysiologyCriteria(),
    showGraphicAvgStudent: false,
    showGraphics: false,
    physiologyId: null,
    studentId: null,
    onTabName: 'yieldByClass',
    selectedByStudent: [],
    optionsTeachers: [],
    selectedByClass: [],
    optionsClass: [],
    itemStudent: [],
    students: [],
    emptyText: 'Faça uma pesquisa para preencher esta tabela',
    fieldsByClass: [
      {
        key: 'seasonName',
        label: 'NOME DA TEMPORADA',
        class: 'left-header-border text-center',
        sortable: true

      },
      {
        key: 'className',
        label: 'NOME DA TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'season',
        label: 'ANO DA TEMPORADA',
        class: 'right-header-border text-center',
        sortable: true
      }
    ],
    fieldsByStudent: [
      {
        key: 'studentName',
        label: 'NOME DO ALUNO',
        class: 'left-header-border text-center'
      },
      {
        key: 'seasonName',
        label: 'NOME DA TEMPORADA',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'className',
        label: 'NOME DA TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'season',
        label: 'ANO DA TEMPORADA',
        class: 'right-header-border text-center',
        sortable: true
      }
    ],
    pagination: {
      count: 1,
      limit: 20,
      index: 1
    },
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Relatórios',
            active: true
          },
          {
            text: 'Fisiologia',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAllTeacher()
  },

  methods: {
    getAllByClass() {
      loading.push()
      PhysiologyReportRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.students = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          if (this.students.length == 0) {
            toast.info('Nenhum registro encontrado', 'AVISO')
            this.physiologyId = null
            this.showGraphics = false
            this.selectedByClass= []
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar alunos', 'ERRO')
        })
    },

    getAllByStudent() {
      if (this.criteria.studentName == null || this.criteria.studentName == "") {
        toast.info('Preencha o nome do estudante', 'AVISO')
        this.showGraphicAvgStudent = false
        this.selectedByStudent = []
        this.itemStudent = []
        return
      }

      loading.push()
      PhysiologyReportRepository.GetByStudentName(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.itemStudent = res.data.data

          if (this.itemStudent.length == 0) {
            toast.info('Nenhum aluno encontrado', 'AVISO')
            this.itemStudent = []
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar aluno', 'ERRO')
        })
    },

    rowSelectedClass(items) {
      this.selectedByClass = items
      this.physiologyId = items.id
      this.showGraphics = true
    },

    rowSelectedStudent(items) {
      this.selectedByStudent = items
      this.studentId = items.studentId
      this.showGraphicAvgStudent = true
    },

    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    getClass() {
      if (!this.criteria.teacherId) {
        this.optionsClass = []
        return
      }

      loading.push()
      ClassRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

    setTabName(tabName) {
      this.onTabName = tabName
    },

    checkTabName() {
      if (this.onTabName == 'yieldByClass') {
        this.showGraphics = false
        this.selectedByClass = []
        this.clearYieldByStudent()
        this.getAllByClass()
      } else if (this.onTabName == 'yieldByStudent') {
        this.showGraphicAvgStudent = false
        this.selectedByStudent = []
        this.clearYieldByClass()
        this.getAllByStudent()
      }
    },

    clearYieldByClass() {
      this.criteria.teacherId = null
      this.criteria.seasonName = null
      this.criteria.classId = null
      this.criteria.season = null
      this.showGraphics = false
      this.selectedByClass = []
      this.students = []
      this.criteria.count = 1
      this.criteria.index = 1
    },

    clearYieldByStudent() {
      this.criteria.studentId = null
      this.criteria.studentName = null
      this.showGraphicAvgStudent = false
      this.itemStudent = []
      this.selectedByStudent = []
    }
  }
}
