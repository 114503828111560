import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'physiologyyoyo'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(criteria, pagination) 
})

const GetById = (id, pagination) => SocialProjectManager.get(`${resourceName}/${id}`, { 
  params: merge(pagination) 
})

const GetPhysiologyYoyoByPhysiologyId = (physiologyId, pagination) => SocialProjectManager.get(`${resourceName}/physiology/${physiologyId}`, { 
  params: merge(pagination) 
})

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const ExportExcel = (id, classAverage) => SocialProjectManager.get(`${resourceName}/download-excel-yoyo/${id}`, {
  params: { classAverage }
})

const PhysiologyYoyoRepository = {
  GetById,
  GetAll, 
  GetPhysiologyYoyoByPhysiologyId,
  Update,
  Delete,
  ExportExcel
}

export default PhysiologyYoyoRepository