export default class studentphysiologycriteria {
  constructor({
    studentName,
    studentId,
    teacherId,
    seasonName,
    classId,
    season,
  } = {}) {
    this.studentName = studentName,
    this.seasonName = seasonName,
    this.classId = classId,
    this.studentId = studentId,
    this.teacherId = teacherId,
    this.season = season
  }
}